import React from 'react';
import '../../App.css';
import DjList from '../DJList';
import { Tabs } from '../UserTabs';



export default function UserHome() {
  return (
      <div className='userHome'>
          <DjList />
    </div>
  );
}
